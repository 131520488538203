import React, { Component } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image";
import Axios from 'axios';

import { ThemeProvider } from '../context/ThemeProvider';
import Layout from '../components/layout';
import listingService from '../services/ListingService';
// import Image from '../components/Image';
// import { css } from 'glamor';
import { toDanishCurrency } from '../utilities/helpers';

import loader from '../images/page-loader.svg';
import bannerImage from '../images/nespresso-banner.jpg';
import bannerImage375 from '../images/nespresso-banner-375.jpg';
import '../styles/listing.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Listings extends Component {
    constructor() {
        super();

        this.state = {
            listings: [],
            paging: {
                size: 15,
                pageNumber: 1
            },
            loading: true,
            screenSize: {
                width: 0,
                height: 0
            },
            search: {
                checkin: '',
                checkout: '',
                guests: 0
            }
        }

        this.onResize = this.onResize.bind(this);
        this.getSearchQuery = this.getSearchQuery.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
        this.getListings();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        source.cancel();
    }

    onResize() {
        const screenSize = {
            width: document.body.clientWidth,
            height: document.body.clientHeight
        };
        this.setState({ screenSize });
    }

    resizeImageContainers() {
        // Adjust padding-bttom that is set by the gatsby-image plugin, this is to make sure that all image have the same dimension
        let imageContainer = document.getElementsByClassName("listings__image");
        let liContainer = document.querySelectorAll(".listings-container > li");
        let paddingBottom = "";
        let listHeight = 0;
        let listWidth = 0;

        for (let a = 0; a < imageContainer.length; a++) {
            const element = imageContainer[a];
            
            let paddedElement = element.querySelector("div.gatsby-image-wrapper > div");
            if (paddedElement.style.paddingBottom > paddingBottom) {
                paddingBottom = paddedElement.style.paddingBottom;
            }
        }

        for (let b = 0; b < imageContainer.length; b++) {
            const element = imageContainer[b];
            
            let paddedElement = element.querySelector("div.gatsby-image-wrapper > div");
            if (paddedElement.style.paddingBottom < paddingBottom) {
                paddedElement.style.paddingBottom = paddingBottom;
            }
        }

        for (let l = 0; l < liContainer.length; l++) {
            const element = liContainer[l];
            
            if (element.getBoundingClientRect().height > listHeight) {
                listHeight = element.getBoundingClientRect().height;
            }

            if (element.getBoundingClientRect().width > listWidth) {
                listWidth = element.getBoundingClientRect().width;
            }
        }

        for (let x = 0; x < liContainer.length; x++) {
            const element = liContainer[x];
            
            if (element.getBoundingClientRect().height < listHeight) {
                element.style.height = listHeight + 'px';
            }
            
            if (element.getBoundingClientRect().width < listWidth) {
                element.style.width = listWidth + 'px';
            }
        }

    }

    getListings(qCheckin, qCheckout, qGuests) {
        const { state } = this.props.location;
        source.cancel();
        source = CancelToken.source();
        let search = {
            checkin: qCheckin,
            checkout: qCheckout,
            guests: qGuests
        }

        if (state !== null && Object.keys(state).length > 0) {
            let { checkin, checkout, guests } = state;

            checkin = qCheckin || checkin;
            checkout = qCheckout || checkout;
            guests = qGuests || guests;

            listingService.getAvailableListings(checkin, checkout, guests, source.token)
            .then(response => {
                if (response.success) {
                    if (response.data) {
                        this.setState({ listings: response.data, loading: false, search }, () => {
                            //this.resizeImageContainers()
                        });
                    }
                }
            });
        }
        else {
            listingService.getAvailableListings(qCheckin, qCheckout, qGuests, source.token)
            .then(response => {
                if (response.success) {
                    if (response.data) {
                        this.setState({ listings: response.data, loading: false, search }, () => {
                            //this.resizeImageContainers()
                        });
                    }
                }
            });
        }
    }

    onSearchClick = ({ checkin, checkout, guests }) => {
        this.setState({ loading: true }, () => {
            this.getListings(checkin, checkout, guests);
        });
    }

    getSearchQuery() {
        const { location } = this.props;
        const { search } = this.state;

        let checkin = '', checkout = '', guests = 0;
        if (location.state) {
            checkin = location.state.checkin || search.checkin;
            checkout = location.state.checkout || search.checkout;
            guests = location.state.guests || search.guests;
        }
        else {
            checkin = search.checkin;
            checkout = search.checkout;
            guests = search.guests;
        }

        return {
            checkin,
            checkout,
            guests
        }
    }

    render() {
        const { location, mediaCollection, pageContext } = this.props;
        const { layout, seo, url, theme } = pageContext;
        const { listings, loading, screenSize } = this.state;

        const query = this.getSearchQuery();

        const searchBarSettings = {
            pathname: location.pathname,
            pageType: 'Listings',
            onSearchClick: this.onSearchClick,
            checkin: query.checkin,
            checkout: query.checkout,
            guests: query.guests
        }

        let panoramicImagePath = layout.footer.panoramicImage.url.split('/');
        let homePageLogoImagePath = layout.homePageLogo.url.split('/');
        let panoramicMediaQuery = mediaCollection.find(x => panoramicImagePath[panoramicImagePath.length - 1] === x.fluid.originalName);
        let homPageLogoQuery = mediaCollection.find(x => homePageLogoImagePath[homePageLogoImagePath.length - 1] === x.fluid.originalName);

        if (layout.footer) {
            layout.footer.panoramicImageQuery = panoramicMediaQuery;
            layout.footer.footerImageQuery = homPageLogoQuery;
        }

        if (layout.header) {
            layout.header.headerImageQuery = homPageLogoQuery;
        }

        seo.customLdScript = [];

        return (
            <ThemeProvider theme={theme}>
                <Layout header={layout.header} footer={layout.footer} favicon={layout.favicon} searchBar={searchBarSettings} scrollYIndex={30} defaultHeaderTextToBlack={true} showHeaderMark={false} seo={seo} path={url}>
                    <div style={{marginTop: 70}}></div>
                    {
                        loading && <div className="listings-loader"><img src={loader} alt="Loading..." /></div>
                    }
                    {
                        (loading === false && listings.length > 0) &&
                        <ul className="layout-row listings-container">
                            {
                                listings.map((listing, index) => {
                                    let path = listing.pictures[0].url.split('/');
                                    let mediaQuery = mediaCollection.find(x => x.fluid.originalName === `${path[path.length - 1]}`);

                                    return (
                                        <li className="flex-2-cols" key={listing.listingId}>
                                            <Link to={listing.url} state={{
                                                checkin: query.checkin,
                                                checkout: query.checkout,
                                                guests: query.guests
                                            }}>
                                                <div className="listings__image">
                                                    <Img fluid={mediaQuery.fluid} alt={listing.title} />
                                                </div>
                                                <div className={`listings__title`}>{listing.title}</div>
                                                <ul className={`listings__features`}>
                                                    <li>
                                                        <span>{`${listing.accommodates} guests`}</span>
                                                        <span>{`${listing.bedrooms} bedrooms`}</span>
                                                        <span>{`${listing.bathrooms} bathrooms`}</span>
                                                    </li>
                                                    <li>{`From ${toDanishCurrency(listing.priceDKK)}`}</li>
                                                </ul>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }
                    {
                        (listings.length <= 0 && loading === false) && <div className="listings-empty-result">
                            <p>Sorry, no properties found based on specified dates. Please try other dates.</p>
                        </div>
                    }
                    <div style={{margin: '1.5rem'}}>
                        {
                            screenSize.width <= 576 && screenSize.width > 0 ? <img src={bannerImage375} alt="nespresso" /> : <img src={bannerImage} alt="nespresso" />
                        }
                    </div>
                </Layout>
            </ThemeProvider>
        )
    }
}

export default props => (
    <StaticQuery query={graphql`
        query lsmediaCollection {
            allImageSharp {
                nodes {
                    fluid(maxWidth: 1920, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp,
                        originalName
                    },
                    thumbnail:fixed(width: 100, height: 110) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `} 
    render={data => <Listings mediaCollection={data.allImageSharp.nodes} {...props}/>}/>
)